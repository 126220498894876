/**
 * Get all focusable elements in the provided root element.
 * @param root the root element to search for focusable elements in
 * @returns all focusable elements in the provided root element
 */
export function getFocusableElements(root: HTMLElement | null): HTMLElement[] {
    if (!root) return []

    const focusableElements = root.querySelectorAll([
        'a[href]',
        'area[href]',
        'input:not([disabled]):not([type="hidden"])',
        'select:not([disabled])',
        'textarea:not([disabled])',
        'button:not([disabled])',
        'iframe',
        'object',
        'embed',
        '[contenteditable]:not([disabled])',
        '[tabindex]:not([tabindex="-1"]):not([disabled])',
    ].join(','))

    return Array.from(focusableElements) as HTMLElement[]
}

/**
 * Returns the first focusable element that is not hidden in the provided root element.
 * @param root the root element to search for focusable elements in
 * @returns the first focusable element that is not hidden in the provided root element
 * or `null` if there are no focusable elements
 */
export function getFirstFocusableElement(root: HTMLElement | null): HTMLElement | null {
    if (!root) return null

    const focusableElements = getFocusableElements(root)

    // find first visible and non-disabled focusable element
    for (const element of focusableElements) {
        const style = getComputedStyle(element)
        const isHidden = style.display === 'none' || style.visibility === 'hidden' || style.opacity === '0'

        if (!isHidden) return element
    }

    return null
}

export function isFocusInElement(element: HTMLElement | null): boolean {
    if (!element) return false

    const activeElement = document.activeElement

    if (!activeElement) return false

    return element.contains(activeElement)
}

/**
 * Replaces all newlines in the provided input string with `<br>` tags.
 *
 * Additionally, it also trims the text before the replacement, so that
 * there are no leading / trailing spaces & or newlines.
 * @param input the input string to parse
 */
export function parseNewlinesIntoBr(input: string | null | undefined): string {
    if (!input) return ''
    return input.trim().replace(/\n/g, '<br>')
}
